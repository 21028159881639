import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Footer from "../../components/Footer/Footer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Header from "../../components/Header/Header.js";
const dashboardRoutes = [];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        paddingTop: "5rem",
    },
    fixedHeight: {
        height: 240,
    },
}));

function Impressum() {
    const classes = useStyles();

    //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <main className={classes.content}>
            <Header
                color="transparent"
                backgroundColor="rgb(244, 244, 245)"
                routes={dashboardRoutes}
                brand="Welcome to TensorParc. My name is Isa."
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
            />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                    <Paper className={classes.paper}>

                    <p className={classes.datefont}>Effective: January 15, 2020</p>

                    <h1>TensorParc's Terms of Services</h1>
                    <h4>In a nutshell:</h4>
                    <p>We use these basic terms throughout the agreement, and they have specific meanings. You should know what we mean when we use each of the terms.</p>

                    <h4>Definitions</h4>
                    <b>Account</b>
                    <p> An Account represents your legal relationship with TensorParc. A “User Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity on TensorParc.
                    </p>

                    <b>Account</b>
                    <p> An Account represents your legal relationship with TensorParc. A User Account represents an individual User's authorization to log in to and use the Service and serves as a User's identity on TensorParc.
                    </p>

                    <b> Agreement</b>
                    <p> Agreement refers collectively, to all the terms, conditions, notices contained or referenced in this document and all other operating rules, policies (including the TensorParc Privacy Statement, available at <a href="https://TensorParc.com/privacy">TensorParc.com/privacy</a>) and procedures that we may publish from time to time on the website.
                    </p>

                    <b> TensorParc, We, and Us </b>
                    <p> TensorParc, We, and Us refer to TensorParc, Inc., as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees, unless we explicitly state otherwise.
                    </p>

                    <b> Services </b>
                    <p> Service and Services refers to the applications, software, products, and services provided by TensorParc, including any Beta Previews.
                    </p>

                    <b> User, you </b>
                    <p> User and You refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age. Special terms may apply for business or government Accounts.
                    </p>

                    <b> Website</b>
                    <p> Website refers to TensorParc's website located at <a href="https://TensorParc.com/">TensorParc.com</a>, and all content, services, and products provided by TensorParc at or through the Website. It also refers to TensorParc-owned subdomains of TensorParc.com, such as <a href="https://isa.TensorParc.com/">isa.TensorParc.com</a>. .Occasionally, websites owned by TensorParc may provide different or additional terms of service. If those additional terms conflict with this Agreement, the more specific terms apply to the relevant page or service.
                    </p>


                    <h4>Account Terms </h4>
                    <h4>In a nutshell:</h4>
                    <p>User Accounts have different administrative controls; a human must create your Account; you must be 13 or over; you must provide a valid email address; and you may not have more than one free Account. You alone are responsible for your Account and anything that happens while you are signed in to or using your Account. You are responsible for keeping your Account secure.</p>

                    <h4>Account Controls</h4>
                    <b>Users</b>
                    <p>Subject to these Terms, you retain ultimate administrative control over your User Account and the content within it.</p>


                    <h4>Required Information</h4>
                    <p>You must provide a valid email address in order to complete the signup process. Any other information requested, such as your real name, is optional, unless you are accepting these terms on behalf of a legal entity (in which case we need more information about the legal entity).
                    </p>

                    <h4>Account Requirements</h4>

                    <p>You must be a human to create an Account. Accounts registered by bots; or other automated methods are not permitted.
                    </p>

                    <p>One person or legal entity may maintain no more than one free Account.
                    </p>
                    <p>You must be age 13 or older. While we are thrilled to see brilliant young learners get excited by learning, we must comply with United States law. TensorParc does not target our Service to children under 13, and we do not permit any Users under 13 on our Service. If we learn of any User under the age of 13, we will terminate that User's Account immediately. If you are a resident of a country outside the United States, your country's minimum age may be older; in such a case, you are responsible for complying with your country's laws.
                    </p>
                    <p>Your login may only be used by one person; i.e., a single login may not be shared by multiple people.
                    </p>
                    <p>You may not use TensorParc in violation of export control or sanctions laws of the United States or any other applicable jurisdiction. You may not use TensorParc if you are or are working on behalf of a <a href="https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx">Specially Designated National (SDN)</a> or a person subject to similar blocking or denied party prohibitions administered by a U.S. government agency.
                    </p>


                    <h4>User Account Security</h4>
                    <p>You are responsible for keeping your Account secure while you use our Service. We offer tools such as two-factor authentication to help you maintain your Account&apos;s security, but the content of your Account and its security are up to you.
                    </p>

                    <p>You are responsible for all content posted and activity that occurs under your Account
                    </p>

                    <p>You are responsible for maintaining the security of your Account and password. TensorParc cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
                    </p>

                    <p>You will promptly notify TensorParc at support@tensorparc.com if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.
                    </p>


                    <h4>Collaborative Projects</h4>
                    <h4>In a nutshell:</h4>
                    <p>
                    TensorParc hosts a wide variety of collaborative projects from all over the world, and that collaboration only works when our users are able to work together in good faith. While using the service, you must follow the terms of this section, which include some restrictions on content you can post, conduct on the service, and other limitations. In short, be excellent to each other.
                    </p>

                    <p>Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.
                    </p>

                    <p>You agree that you will not under any circumstances violate theses Terms and <a href="https://isa.TensorParc.com/privacy">our privacy policy</a>.
                    </p>

                    <h4>User-Generated Content</h4>
                    <h4>In a nutshell:</h4>
                    <p>You own content you create, but you allow us certain rights to it, so that we can display and share the content you post. You still have control over your content, and responsibility for it, and the rights you grant us are limited to those we need to provide the service. We have the right to remove content or close Accounts if we need to.
                    </p>

                    <h4>Responsibility for User-Generated Content</h4>
                    <p>You may create or upload User-Generated Content while using the Service. You are solely responsible for the content of, and for any harm resulting from, any User-Generated Content that you post, upload, link to or otherwise make available via the Service, regardless of the form of that Content. We are not responsible for any public display or misuse of your User-Generated Content.
                    </p>

                    <h4>TensorParc may Remove Content</h4>
                    <p>We do not pre-screen User-Generated Content, but we have the right (though not the obligation) to refuse or remove any User-Generated Content that, in our sole discretion, violates any TensorParc terms or policies.
                    </p>

                    <h4> Ownership of Content, Right to Post, and License Grants</h4>
                    <p>You retain ownership of and responsibility for Your Content. If you are posting anything you did not create yourself or do not own the rights to, you agree that you are responsible for any Content you post; that you will only submit Content that you have the right to post; and that you will fully comply with any third party licenses relating to Content you post.
                    </p>

                    <p>Because you retain ownership of and responsibility for Your Content, we need you to grant us &#x2014; and other TensorParc Users certain legal permissions. These license grants apply to Your Content. If you upload Content that already comes with a license granting TensorParc the permissions we need to run our Service, no additional license is required. You understand that you will not receive any payment for any of the rights granted. The licenses you grant to us will end when you remove Your Content from our servers, unless other Users have used it.</p>

                    <h4>License Grant to Us</h4>
                    <p>We need the legal right to do things like host Your Content, publish it, and share it. You grant us and our legal successors the right to store, parse, and display Your Content, and make incidental copies as necessary to render the Website and provide the Service. This includes the right to do things like copy it to our database and make backups; show it to you and other users; parse it into a search index or otherwise analyze it on our servers; share it with other users; and perform it, in case Your Content is something like music, a gif, images or video or other content.</p>
                    <p>This license does not grant TensorParc the right to sell Your Content or otherwise distribute or use it outside of our provision of the Service.
                    </p>

                    <h4>License Grant to Other Users</h4>
                    <p>Any User-Generated Content you post publicly, including issues, comments, and contributions to other Users&apos; projects, may be viewed by others. By setting your projects to be viewed publicly, you agree to allow others to view and &quot;use&quot; your projects (this means that others may make their own copies of Content from your projects in projects they control).
                    </p>

                    <p>If you set your pages and projects to be viewed publicly, you grant each User of TensorParc a nonexclusive, worldwide license to use, display, and perform Your Content through the TensorParc Service and to reproduce Your Content solely on TensorParc as permitted through TensorParc's functionality (for example, through using the project of another user).  If you are uploading Content you did not create or own, you are responsible for ensuring that the Content you upload is licensed under terms that grant these permissions to other TensorParc Users.
                    </p>

                    <h4>Contributions Under project License</h4>
                    <p>Whenever you make a contribution to a project containing notice of a license, you license your contribution under the same terms, and you agree that you have the right to license your contribution under those terms. If you have a separate agreement to license your contributions under different terms, such as a contributor license agreement, that agreement will supersede.
                    </p>

                    <h4>Moral Rights</h4>
                    <p>You retain all moral rights to Your Content that you upload, publish, or submit to any part of the Service, including the rights of integrity and attribution. However, you waive these rights and agree not to assert them against us, to enable us to reasonably exercise the rights granted, but not otherwise.
                    </p>
                    <p>To the extent this agreement is not enforceable by applicable law, you grant TensorParc the rights we need to use Your Content without attribution and to make reasonable adaptations of Your Content as necessary to render the Website and provide the Service.
                    </p>

                    <h4>Access</h4>
                    <p>TensorParc employees may only access the content of your private projects in the following situations:
                    </p>
                    <ul>
                    <li>With your consent and knowledge, for support reasons. If TensorParc accesses a private project for support reasons, we will only do so with the owner's consent and knowledge.</li>
                    <li>When access is required for security reasons, including when access is required to maintain ongoing confidentiality, integrity, availability and resilience of TensorParc's systems and Service.</li>
                    </ul>
                    <p>You may choose to enable additional access to your private projects. For example:</p>
                    <ul>
                    <p>You may enable various TensorParc services or features that require additional rights to Your Content in private projects. These rights may vary depending on the service or feature, but TensorParc will continue to treat your private project Content as confidential. If those services or features require rights in addition to those we need to provide the TensorParc Service, we will provide an explanation of those rights.</p>
                    </ul>

                    <h4>Exclusions</h4>
                    <p>If we have reason to believe the contents of a private project are in violation of the law or of these Terms, we have the right to access, review, and remove them. Additionally, we may be compelled by law to disclose the contents of your private projects.
                    </p>

                    <h4>Copyright Infringement and DMCA Policy</h4>
                    <p>If you believe that content on our website violates your copyright, please contact us. If you are a copyright owner and you believe that content on TensorParc violates your rights, please contact us by emailing copyright@TensorParc.com. There may be legal consequences for sending a false or frivolous takedown notice. Before sending a takedown request, you must consider legal uses such as fair use and licensed uses.
                    </p>

                    <p>We will terminate the Accounts of repeat infringers of this policy.</p>

                    <h4>Intellectual Property Notice</h4>
                    <p><strong>In a nutshell:</strong> We own the service and all of our content. In order for you to use our content, we give you certain rights to it, but you may only use our content in the way we have allowed.</p>
                    <h4>TensorParc's Rights to Content</h4>
                    <p>TensorParc and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual property rights of any kind related to the Website and Service. We reserve all rights that are not expressly granted to you under this Agreement or by law. The look and feel of the Website and Service is copyright &#xA9; TensorParc, Inc. All rights reserved. You may not duplicate, copy, or reuse any portion of the code, images, text or visual design elements or concepts without express written permission from TensorParc.
                    </p>
                    <h4>TensorParc Logos</h4>
                    <p>If you would like to use TensorParc's logos, you must ask for written permission.</p>

                    <h4>Cancellation and Termination</h4>
                    <p><strong>In a nutshell:</strong> You may close your Account at any time. If you do, will treat your information responsibly.</p>
                    <h4>Account Cancellation</h4>
                    <p>It is your responsibility to properly cancel your Account with TensorParc. You can cancel your Account at any time by going into your Settings in the global navigation bar at the top of the screen. The Account screen provides a simple, no questions asked cancellation link. We are not able to cancel Accounts in response to an email or phone request.
                    </p>

                    <h4>Upon Cancellation</h4>
                    <p>We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile and the Content of your projects within 90 days of cancellation or termination (though some information may remain in encrypted backups). This information can not be recovered once your Account is cancelled.
                    </p>
                    <p>We will not delete Content that you have contributed to other Users; projects or that other Users have used.</p>
                    <p>Upon request, we will make a reasonable effort to provide an Account owner with a copy of your lawful, non-infringing Account contents after Account cancellation, termination, or downgrade. You must make this request within 90 days of cancellation, termination, or downgrade.
                    </p>
                    <h4>TensorParc may Terminate</h4>
                    <p>TensorParc has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. TensorParc reserves the right to refuse service to anyone for any reason at any time.
                    </p>
                    <h4>Survival</h4>
                    <p>All provisions of this Agreement which, by their nature, should survive termination will survive termination; including, without limitation: ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
                    </p>

                    <h4>Communicating with TensorParc</h4>
                    <p><strong>In a nutshell:</strong> We use email and other electronic means to stay in touch with our users.</p>
                    <h4>Electronic Communication Required</h4>
                    <p>For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.
                    </p>
                    <h4>Legal Notice to TensorParc Must Be in Writing</h4>
                    <p>Communications made through email or TensorParc Support's messaging system will not constitute legal notice to TensorParc or any of its officers, employees, agents or representatives in any situation where notice to TensorParc is required by contract or any law or regulation. Legal notice to TensorParc must be in writing and served on TensorParc's legal agent.
                    </p>
                    <h4>No Phone Support</h4>
                    <p>TensorParc only offers support via email, in-Service communications, and electronic messages. We do not offer telephone support.</p>
                    <h4>Disclaimer of Warranties</h4>
                    <p><strong>In a nutshell:</strong> We provide our service as is, and we make no promises or guarantees about this service. Please read this section carefully; you should understand what to expect.
                    </p>
                    <p>TensorParc provides the Website and the Service as is; and as available without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.
                    </p>
                    <p>TensorParc does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.
                    </p>

                    <h4>Limitation of Liability</h4>
                    <p><strong>In a nutshell:</strong> We will not be liable for damages or losses arising from your use or inability to use the service or otherwise arising under this agreement. Please read this section carefully; it limits our obligations to you.
                    </p>
                    <p>You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from
                    </p>
                    <ul>
                    <li>the use, disclosure, or display of your User-Generated Content;</li>
                    <li>your use or inability to use the Service;</li>
                    <li>any modification, price change, suspension or discontinuance of the Service;</li>
                    <li>the Service generally or the software or systems that make the Service available;</li>
                    <li>unauthorized access to or alterations of your transmissions or data;</li>
                    <li>statements or conduct of any third party on the Service;</li>
                    <li>any other user interactions that you input or receive through your use of the Service; or</li>
                    <li>any other matter relating to the Service.</li>
                    </ul>
                    <p>Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control.
                    </p>

                    <h4>Release and Indemnification</h4>
                    <p><strong>In a nutshell:</strong> You are responsible for your use of the service. If you harm someone else or get into a dispute with someone else, we will not be involved.
                    </p>
                    <p>If you have a dispute with one or more Users, you agree to release TensorParc from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
                    </p>
                    <p>You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys&#x2019; fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement, provided that TensorParc (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases TensorParc of all liability); and (3) provides to you all reasonable assistance, at your expense.
                    </p>

                    <h4>Changes to These Terms</h4>
                    <p><strong>In a nutshell:</strong> We want our users to be informed of important changes to our terms, but some changes aren't that important; we don't want to bother you every time we fix a typo. So while we may modify this agreement at any time, we will notify users of any changes that affect your rights and give you time to adjust to them.
                    </p>
                    <p>We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users of material changes to this Agreement, such as price changes, at least 30 days prior to the change taking effect by posting a notice on our Website. For non-material modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service. You can view all changes to these Terms.
                    </p>

                    <p>We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.
                    </p>

                    <h4>Miscellaneous</h4>
                    <h4>Governing Law</h4>
                    <p>Except to the extent applicable law provides otherwise, this Agreement between you and TensorParc and any access to or use of the Website or the Service are governed by the federal laws of the United States of America.
                    </p>

                    <h4>Non-Assignability</h4>
                    <p>TensorParc may assign or delegate these Terms of Service and/or the TensorParc Privacy Statement, in whole or in part, to any person or entity at any time with or without your consent, including the license grant in Section D.4. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Statement without our prior written consent, and any unauthorized assignment and delegation by you is void.
                    </p>

                    <h4>Severability, No Waiver, and Survival</h4>
                    <p>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties'; original intent. The remaining portions will remain in full force and effect. Any failure on the part of TensorParc to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.
                    </p>
                    <h4>Amendments; Complete Agreement</h4>
                    <p>This Agreement may only be modified by a written amendment signed by an authorized representative of TensorParc, or by the posting by TensorParc of a revised version. These Terms of Service, together with the TensorParc Privacy Statement, represent the complete and exclusive statement of the agreement between you and us. This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between you and TensorParc relating to the subject matter of these terms including any confidentiality or nondisclosure agreements.
                    </p>
                    <h4>Questions</h4>
                    <p>Do you have questions about the Terms of Service? Contact us at support@tensorparc.com</p>
                             </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
            </main>
        </div>
    );
}

export default Impressum;

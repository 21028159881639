import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
        color: "#2196f3",
    },
    suround: {
        position: "fixed",
        zIndex: "999",
        width: "12em",
        height: "6rem",
        overflow: "visible",
        margin: "auto",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        textAlign: "center",
        borderRadius: "3pt",
        background: "#FFF",
        padding: "2rem",
    },
    backgr: {
        zIndex: "998",
        display: "block",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.3)",
    }
}));

export default function CircularIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.backgr}>
        <div className={classes.suround}>
            <CircularProgress className={classes.progress} />
            <div>something is happening ...</div>
        </div>
        </div>
    );
}

import React, { Fragment, useContext } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import ArrowForward from '@material-ui/icons/ExitToApp';
import Add from '@material-ui/icons/Add';
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "../../assets/jss/material-kit-react/components/headerStyle.js";

import UserMessage from "../../UserMessage";
import UserLogoutButton from "../../UserLogoutButton";
import UserContext from "../../userContext";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const usContext = useContext(UserContext);

  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Button className={classes.title}>{brand}</Button>;

  console.log(usContext)

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container} style={{marginBottom: 0}}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
        <Link to={"/"}>
           <img src="https://i.imgur.com/V6m6Nub.png" style={{ width:"10vh", height:"auto", verticalAlign: "sub" }} alt="logo"/>
        </Link>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
        </div>
        {!usContext.isLoggedIn && (
            <Fragment>
              <Hidden smDown>
                <Link style={{cursor: "pointer", color:"#000", title:"Signin"}} to={"/Signin"}>
                  <ArrowForward /></Link> <Box>Log In</Box>
              </Hidden>
              <Hidden smDown>
                <Link style={{cursor: "pointer", color:"#000", title:"Signup"}} to="/Signup">
                  <Add /></Link> <Box>Register</Box>
              </Hidden>
            </Fragment>
        )}
        <UserMessage/>
        {usContext.isLoggedIn && (
              <Hidden smDown implementation="css">
                <Box marginRight="2em" marginLeft="1em">
              <Button type="button"
              onClick={event => { window.open('/home', '_self') }}>
                    dashboard
                  </Button>
                </Box>
              </Hidden>
        )}



        <UserLogoutButton/>

        <Hidden mdUp>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}

              <UserMessage/>
            <Box marginRight="2em" marginLeft="1em">

          <Button type="button"
          onClick={event => { window.open('/home', '_self') }}>
                dashboard
              </Button>
                    <UserLogoutButton/>
            </Box>

          </div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
  bg: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

import Typography from "@material-ui/core/Typography/Typography";
import Link from "@material-ui/core/Link/Link";
import React, { PureComponent, Fragment } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from "../../components/CustomButtons/Button.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import UserContext, { UserConsumer } from '../../userContext';
import stylescss from "../../assets/jss/material-kit-react/views/landingPage.js";
import UserLoginFail from "../../UserLoginFail";
import { Redirect } from 'react-router'

const styles = theme => (stylescss);

class Signin extends PureComponent {
    static contextType = UserContext;
    state = {
        username: '',
        password: '',
    }

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
    }

    render() {
        const { classes } = this.props;

        if (this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Home`,
                        state: {
                        }
                    }}
                />
            );
        }

  return (
      <UserConsumer>
          {({ login, isLoggedIn, logout }) => (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        <Avatar className={classes.avatar}>
          <ExitToApp />
        </Avatar>
          {!isLoggedIn && (<Fragment><Typography component="h1" variant="h6">
          Sign in
        </Typography>
        <UserLoginFail/>
          <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Email Address"
            name="username"
            autoComplete="email"
            autoFocus
            onChange={this.handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.handleChange}
          />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={event => {
                login(this.state.username, this.state.password);
            }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/Signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
            <Box mt={2} style={{color:"#bbb"}}>
              <p>By continuing, you agree to TensorParc's Terms of Service and Privacy Policy, and to receiving emails with updates.</p>
            </Box>
          </Grid>
        </form></Fragment>)}
        {isLoggedIn && (
            <Button type="button"
                    color="primary"
                    onClick={event => { logout(); }}>
                Log out
            </Button>)}
      </div>
    </Container>
          )}
      </UserConsumer>
  )}
}

export default withStyles(styles, { withTheme: true })(Signin);

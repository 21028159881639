import React, { PureComponent, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../../components/Loader";

import { Link } from 'react-router-dom';
// core components
import { api } from "../../lib/apisauce";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button.js";
import 'react-circular-progressbar/dist/styles.css';

import stylescss from "../../assets/jss/material-kit-react/views/landingPage.js";

import {UserConsumer} from "../../userContext";

const dashboardRoutes = [];

const styles = theme => (stylescss);

class ConfirmRegister extends PureComponent {

    componentDidMount() {
        this.confirmRegister();
    }

    state = {
        code: this.props.match.params.code,
        confirmed: false,
        trycatch: false,
        loading: false
    };

    render() {
        const { classes } = this.props;

        console.log(this.state)

        return (
            <div>
                {this.state.loading && <div className={classes.loader}>
                    <Loader />
                </div>}
                <UserConsumer>
                    {({ isLoggedIn }) => (<Fragment>
                        {this.state.loading && <div className={classes.loader}>
                            <Loader />
                        </div>}
                        <Header
                            color="transparent"
                            backgroundColor="rgb(244, 244, 245)"
                            routes={dashboardRoutes}
                            brand="The world's new learning front page."
                            fixed
                            changeColorOnScroll={{
                                height: 400,
                                color: "white"
                            }}
                        />


                        <div style={{ backgroundColor: "rgb(236, 236, 236)", paddingBottom: "4rem", paddingTop: "4rem" }}>
                            <GridContainer className={classes.pagecontainer} style={{ paddingTop: "3rem" }}>
                                {this.state.confirmed && <GridItem xs={12} sm={12} md={12}>
                                    <h3 style={{ paddingBottom: "3rem" }}>Yes! Your account is now activated.</h3>
                                    <Link to="/Signin"><Button color="primary">Login</Button></Link>
                                </GridItem>}
                                {this.state.trycatch && <GridItem xs={12} sm={12} md={12}>
                                    <h3 style={{ paddingBottom: "3rem" }}>Something went wrong. Sorry, please contact help@tensorparc.com</h3>

                                </GridItem>}

                            </GridContainer>
                        </div>

                        <Footer/>
                    </Fragment>)}
                </UserConsumer>
            </div>
        );
    }

    /**
     *
     * @method saveDashboard
     * @memberof LandingPage
     * WIP
     */
    confirmRegister = async () => {
        this.setState({ loading: true});
        const code = this.state.code;
        const response = await api.post(
            "https://apitp.tensorparc.com/putConfirmRegister.php",
            { code }
        );
        const { ok, data } = response || {};
        if (ok) {
            if(data.result.status === '200') {
                this.setState({ confirmed: true});
            } else {
                if (data.result.status === '500') {
                    this.setState({ confirmed: false});
                    this.setState({ trycatch: true});
                }
            }
            this.setState({ loading: false});

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }

            this.setState({ confirmed: false});
            this.setState({ trycatch: true});
            this.setState({ loading: false});
        }
    };

}



export default withStyles(styles, { withTheme: true })(ConfirmRegister);

import Typography from "@material-ui/core/Typography/Typography";
import Link from "@material-ui/core/Link/Link";
import React, {Fragment, PureComponent} from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from "../../components/CustomButtons/Button.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import stylescss from "../../assets/jss/material-kit-react/views/landingPage.js";
import UserRegisterFail from "../../UserRegisterFail";
import { UserConsumer } from "../../userContext";

const styles = theme => (stylescss);

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                TensorParc, Inc
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

class SignUp extends PureComponent {
    state = {
        username: '',
        password: '',
    }

    handleChange=(e)=>{
        let { name, value} = e.target;
        this.setState({[name]: value});
        console.log(this.state)
    }


    render() {
        const { classes } = this.props;

        return (
            <UserConsumer>
                {({ login, isLoggedIn, logout, register, username, password, registerFailure }) => (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    {!registerFailure && (username === 'user' || username === '') && (<Fragment><Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    <UserRegisterFail/>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Email Address"
                                    name="username"
                                    autoComplete="email"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={this.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails"/>}
                                    label="By continuing, you agree to TensorParc's Terms of Service and Privacy Policy, and to receiving emails with updates."
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="button"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={event => {
                                register(this.state.username, this.state.password);
                            }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="#" variant="body2">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </form></Fragment>)}
                    {username !== 'user' && password && !registerFailure && (
                        <Fragment>Your registration was successful. please check your e-mail to activate your account.</Fragment>
                    )}
                </div>
                <Box mt={5}>
                    <Copyright/>
                </Box>
            </Container>
                )}
            </UserConsumer>
        );
    }
}

export default withStyles(styles, { withTheme: true })(SignUp);

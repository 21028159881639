import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Footer from "../../components/Footer/Footer.js";
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Header from "../../components/Header/Header.js";
const dashboardRoutes = [];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        paddingTop: "5rem",
    },
    fixedHeight: {
        height: 240,
    },
}));

function Impressum() {
    const classes = useStyles();

    //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <main className={classes.content}>
            <Header
                color="transparent"
                backgroundColor="rgb(244, 244, 245)"
                routes={dashboardRoutes}
                brand="Welcome to TensorParc. My name is Isa."
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
            />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                    <Paper className={classes.paper}>
                    <p className={classes.datefont}>Effective: January 15, 2020</p>
                    <h1>TensorParc's Privacy Statement</h1>
<h3>What information TensorParc collects</h3>
<p><b>"user personal information"</b> is any information about one of our users which could, alone or together with other information, personally identify them or otherwise be reasonably linked or connected with them. information such as a username and password, an email address, a real name, an Internet protocol (IP) address, and a photograph are examples of “user personal information.”</p>
<p>
user personal information does not include aggregated, non-personally identifying information that does not identify a user or cannot otherwise be reasonably linked or connected with them. We may use such aggregated, non-personally identifying information for research purposes and to operate, analyze, improve, and optimize our website and Service.
</p>

<h3>information users provide to TensorParc</h3>
<b>Registration information</b>
<p>
We require some basic information at the time of account creation. When you create your own username and password, we ask you for a valid email address.
</p>

<b>Profile information</b>
<p> You may choose to give us more information for your account profile, such as your full name, an avatar which may include a photograph, your biography, your location, your company, and a URL to a third-party website. This information may include user personal information. Please note that your profile information may be visible to others. This can be users of our service.
</p>
<b>information TensorParc automatically collects from you, when you use our service</b>

<b>Usage information</b>
<p>
If you're accessing our Service or website, we automatically collect the same basic information that most services collect, subject, where necessary, to your consent. This includes information about how you use the Service, such as the pages you view, the referring site, your IP address and session information, and the date and time of each request. This is information we collect from every visitor to the website, whether they have an Account or not. This information may include user personal information.</p>

<b>Cookies and Similar Technologies information</b>
<p>As further described below, and subject, where applicable, to your consent, we automatically collect information from cookies and similar technologies (such as cookie ID and settings) to keep you logged in, to remember your preferences, and to identify you and your device.
</p>
<b>Device information</b>
<p>We may collect certain information about your device, such as its IP address, browser or client application information, language preference, operating system and application version, device type and ID, and device model and manufacturer. This information may include user personal information.
</p>


<b>information we collect from third parties</b>
<p>
TensorParc may collect user personal information from third parties. For example, this may happen if you sign up for training or to receive information about TensorParc from one of our vendors, partners, or affiliates. TensorParc does not purchase user personal information from third-party data brokers.
</p>
<h3>What information TensorParc does not collect</h3>
<p>
We do not intentionally collect “Sensitive personal information”, such as personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation. If you choose to store any Sensitive personal information on our servers, you are responsible for complying with any regulatory controls regarding that data.
</p>
<p>
<b>If you are a child under the age of 13</b>, you may not have an account on TensorParc. TensorParc does not knowingly collect information from or direct any of our content specifically to children under 13. If we learn or have reason to suspect that you are a user who is under the age of 13, we will have to close your account. We don't want to discourage you from learning or researching, but those are the rules we want and have to comply with. Please see our Terms of Service for information about Account termination. Different countries may have different minimum age limits, and if you are below the minimum age for providing consent for data collection in your country, you may not have an account on TensorParc.
</p>
<p>
We do not intentionally collect user personal information that is stored in your projects or other free-form content inputs. Any personal information within a user's project is the responsibility of the project owner.
</p>

<h3>How TensorParc uses your information</h3>
<p>We may use your information for the following purposes:</p>
<ul>
<li>We use your Registration information to create your account, and to provide you the Service.</li>
<li>We use your user personal information, specifically your username, to identify you on TensorParc.</li>
<li>We use your Profile information to fill out your Account profile and to share that profile with other users if you ask us to.</li>
<li>We use your email address to communicate with you, if you've said that's fine. </li>
<li>We use user personal information to respond to support requests.</li>
<li>We use user personal information and other data to make recommendations for you, such as to suggest projects you may want to follow or contribute to. We learn from your public behavior on TensorParc—such as the projects you saved—to determine your topic interests, and we recommend similar projects. These recommendations are automated decisions, but they have no legal impact on your rights.</li>
<li>We may use user personal information to invite you to take part in surveys, beta programs, or other research projects, subject, where necessary, to your consent.</li>
<li>We use Usage information and Device information to better understand how our users use TensorParc and to improve our website and Service.</li>
<li>We may use your user personal information if it is necessary for security purposes or to investigate possible fraud or attempts to harm TensorParc or our users.</li>
<li>We may use your user personal information to comply with our legal obligations, protect our intellectual property, and enforce our Terms of Service.</li>
<li>We limit our use of your user personal information to the purposes listed in this privacy statement. If we need to use your user personal information for other purposes, we will ask your permission first. You can always see what information we have, how we're using it, and what permissions you have given us in your user profile.</li>
</ul>

<h3>Our legal bases for processing information</h3>
<p>To the extent that our processing of your user personal information is subject to certain international laws (including, but not limited to, the European Union's General Data Protection Regulation (GDPR)), TensorParc is required to notify you about the legal basis on which we process user personal information. TensorParc processes user personal information on the following legal bases:
</p>

<b>Contract Performance:</b>
<p>
When you create a TensorParc Account, you provide your Registration information. We require this information for you to enter into the Terms of Service agreement with us, and we process that information on the basis of performing that contract. We also process your username and email address on other legal bases, as described below.
</p>

<b>Consent:</b>
<p>

We rely on your consent to use your user personal information under the following circumstances: when you fill out the information in your user profile; when you decide to participate in a TensorParc training, research project, beta program, or survey; and for marketing purposes, where applicable. All of this user personal information is entirely optional, and you have the ability to access, modify it at any time. You may withdraw your consent at any time. Please notify us immediately if you do so by writing to privacy@tensorparc.com stating the email address you are using on our site and the wish that you
</p>
<b>
Legitimate Interests:
</b>

<p>
Generally, the remainder of the processing of user personal information we perform is necessary for the purposes of our legitimate interest, for example, for legal compliance purposes, security purposes, or to maintain ongoing confidentiality, integrity, availability, and resilience of TensorParc’s systems, website, and Service.
If you would like to request deletion of data we process on the basis of consent or if you object to our processing of personal information, please write to privacy@tensorparc.com.
</p>
<h3>How we share the information we collect</h3>
<p>We may share your user personal information with third parties under one of the following circumstances:</p>

<b>With your consent</b>
<p>We share your user personal information, if you consent, after letting you know what information will be shared, with whom, and why. For example, if you purchase an application listed on our Marketplace, we share your username to allow the application Developer to provide you with services. Additionally, you may direct us through your actions on TensorParc to share your user personal information. For example, if you join an Organization, you indicate your willingness to provide the owner of the Organization with the ability to view your activity in the Organization’s access log.</p>

<b>With service providers</b>
<p>We share user personal information with a limited number of service providers who process it on our behalf to provide or improve our Service, and who have agreed to privacy restrictions similar to the ones in our privacy statement by signing data protection agreements or making similar commitments. Our service providers perform payment processing, customer support ticketing, network data transmission, security, and other similar services. While TensorParc processes all user personal information in the United States, our y service providers may process data outside of the United States or the European Union. If you would like to know who our service providers are, please see our page on Subprocessors.
</p>

<b>For security purposes</b>
<p>If you are a member of an Organization, TensorParc may share your username, Usage information, and Device information associated with that Organization with an owner and/or administrator of the Organization who has agreed to the Corporate Terms of Service or applicable customer agreements, to the extent that such information is provided only to investigate or respond to a security incident that affects or compromises the security of that particular Organization.
</p>

<b>For legal disclosure</b>
<p>TensorParc strives for transparency in complying with legal process and legal obligations. Unless prevented from doing so by law or court order, or in rare, exigent circumstances, we make a reasonable effort to notify users of any legally compelled or required disclosure of their information. TensorParc may disclose user personal information or other information we collect about you to law enforcement if required in response to a valid subpoena, court order, search warrant, a similar government order, or when we believe in good faith that disclosure is necessary to comply with our legal obligations, to protect our property or rights, or those of third parties or the public at large.</p>


<h3>Change in control or sale</h3>
<p> We may share user personal information if we are involved in a merger, sale, or acquisition of corporate entities or business units. If any such change of ownership happens, we will ensure that it is under terms that preserve the confidentiality of user personal information, and we will notify you on our website or by email before any transfer of your user personal information. The organization receiving any user personal information will have to honor any promises we made in our privacy statement or Terms of Service.</p>

<b>Aggregate, non-personally identifying information</b>
<p> We share certain aggregated, non-personally identifying information with others about how our users, collectively, use TensorParc, or how our users respond to our other offerings, such as our conferences or events. For example, we may compile statistics on the open source activity across TensorParc.</p>

<p> We do not sell your user personal information for monetary or other consideration.</p>

<b>Please note:</b> <p> The California Consumer Privacy Act of 2018 (“CCPA”) requires businesses to state in their privacy policy whether or not they disclose personal information in exchange for monetary or other valuable consideration. While CCPA only covers California residents, when it goes into effect we will voluntarily extend its core rights for people to control their data to all of our users in the United States, not just those who live in California. You can learn more about the CCPA and how we comply with it here.</p>

<h3>Other important information</h3>
<b>Project contents</b>
<p>
TensorParc employees do not access private projects unless required to for security purposes, to assist the project owner with a support matter, to maintain the integrity of the Service, or to comply with our legal obligations. However, while we do not generally search for content in your projecrts, we may scan our servers and content to detect certain tokens or security signatures, known active malware, or child exploitation imagery. Our Terms of Service provides more details.
</p>
<p>
If your project is public, anyone may view its contents. If you include private, confidential or Sensitive personal information, such as email addresses or passwords, in your public project, that information may be indexed by search engines or used by third parties.
</p>


<h3>Public information on TensorParc</h3>
<p>
Many of TensorParc services and features are public-facing. If your content is public-facing, third parties may access and use it in compliance with our Terms of Service, such as by viewing your profile or projects or pulling data via our API. We do not sell that content; it is yours. However, we do allow third parties, such as research organizations or archives, to compile public-facing TensorParc information. Other third parties, such as data brokers, have been known to scrape TensorParc and compile data as well.
</p>
<p>
Your user personal information associated with your content could be gathered by third parties in these compilations of TensorParc data. If you do not want your user personal information to appear in third parties’ compilations of TensorParc data, please do not make your user personal information publicly available. We currently set users' email address to private by default, but legacy TensorParc users may need to update their settings.
</p>
<p>
If you would like to compile TensorParc data, you must comply with our Terms of Service regarding scraping and privacy, and you may only use any public-facing user personal information you gather for the purpose for which our user authorized it. For example, where a TensorParc user has made an email address public-facing for the purpose of identification and attribution, do not use that email address for commercial advertising. We expect you to reasonably secure any user personal information you have gathered from TensorParc, and to respond promptly to complaints, removal requests, and "do not contact" requests from TensorParc or TensorParc users.
</p>
<p>
Similarly, projects on TensorParc may include publicly available user personal information collected as part of the collaborative process. If you have a complaint about any user personal information on TensorParc, please email privacy@tensorparc.com.
</p>


<h3>Additional services</h3>
<b>Use of Mixpanel</b>
<p>
This website uses Mixpanel, a web analytics service provided by Mixpanel Inc. ('Mixpanel'). Mixpanel uses so-called 'events', text files that are stored on your computer and that allow an analysis of the use of the website by you. The information generated by the event about your use of this website is usually transmitted to a Mixpanel server in the USA and stored there. However, due to the activation of IP anonymization on these websites, your IP address will be shortened beforehand by Mixpanel. If you are in the European Union, this will happen within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be sent to a Mixpanel server in the US and shortened there. On behalf of the operator of this website, Mixpanel will use this information to evaluate your use of the website, to compile reports on website activity and to provide other services related to website activity and internet usage to the website operator. The IP address provided by Mixpanel Analytics as part of Mixpanel Analytics will not be merged with other Mixpanel data. You can prevent the storage of cookies by a corresponding setting of your browser software. We point out, however, that in this case you may not be able to use all functions of this website in full.
</p>

<b>How you can access and control the information we collect</b>
<p>
If you're already a TensorParc user, you may access, update, alter, or delete your basic user profile information by editing your user profile or contacting TensorParc Support. You can control the information we collect about you by limiting what information is in your profile, by keeping your information current, or by contacting TensorParc Support at support@TensorParc.com
</p>
<p>
If TensorParc processes information about you, such as information TensorParc receives from third parties, and you do not have an account, then you may, subject to applicable law, access, update, alter, delete, or object to the processing of your personal information by contacting TensorParc Support.
</p>

<b>Data portability</b>
<p>
As a TensorParc user, you can always take your data with you. You can save your projects to your desktop, for example.
</p>

<b>Data retention and deletion of data</b>
<p>
Generally, TensorParc retains user personal information for as long as your account is active or as needed to provide you services.
</p>
<p>
If you would like to cancel your account or delete your user personal information, you may do so in your user profile or by writing to support@tensorparc.com. We retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, but barring legal requirements, we will delete your full profile (within reason) within 90 days of your request. You may contact TensorParc Support to request the erasure of the data we process on the basis of consent within 30 days.
</p>
<p>
After an account has been deleted, certain data, such as contributions to other users' projects and comments in others' issues, will remain. However, we will delete or de-identify your user personal information, including your username and email address.
</p>


<h3>Our use of cookies and tracking</h3>
<b>Cookies</b>
<p>
TensorParc uses cookies to make interactions with our service easy and meaningful. Cookies are small text files that websites often store on computer hard drives or mobile devices of visitors. We use cookies (and similar technologies, like HTML5 localStorage) to keep you logged in, remember your preferences, and provide information for future development of TensorParc. For security purposes, we use cookies to identify a device. By using our website, you agree that we can place these types of cookies on your computer or device. If you disable your browser or device’s ability to accept these cookies, you will not be able to log in or use TensorParc’s services.
</p>


<h3>How TensorParc secures your information</h3>
<p>TensorParc takes all measures reasonably necessary to protect user personal information from unauthorized access, alteration, or destruction; maintain data accuracy; and help ensure the appropriate use of user personal information.
</p>
<p>
Transmission of data on TensorParc is encrypted using SSH, HTTPS (TLS), and project content is encrypted at rest. We manage our own cages and racks at top-tier data centers with high level of physical and network security, and when data is stored with a third-party storage provider, it is encrypted.
</p>
<p>
No method of transmission, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.
</p>

<h3>TensorParc's global privacy practices</h3>
<p>We store and process the information that we collect in the United States in accordance with this privacy statement though our service providers may store and process data outside the United States. However, we understand that we have users from different countries and regions with different privacy expectations, and we try to meet those needs even when the United States does not have the same privacy framework as other countries.</p>
<p>
We provide a high standard of privacy protection—as described in this privacy statement—to all our users around the world, regardless of their country of origin or location, and we are proud of the levels of notice, choice, accountability, security, data integrity, access, and recourse we provide. We work hard to comply with the applicable data privacy laws wherever we do business, working with our Data Protection Officer as part of a cross-functional team that oversees our privacy compliance efforts. Additionally, if our vendors or affiliates have access to user personal information, they must sign agreements that require them to comply with our privacy policies and with applicable data privacy laws.
</p>

<h3>Cross-border data transfers</h3>
<p>
TensorParc complies with the EU-U.S. Privacy Shield and the Swiss-US Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of user personal information transferred from the European Union to the United States.
</p>
<p>
If there is any conflict between the terms in this privacy statement and the Privacy Shield Principles, the Privacy Shield Principles shall govern.
</p>

<b>How we communicate with you</b>
<p>
We use your email address to communicate with you, if you've said that's okay, and only for the reasons you’ve said that’s fine. For example, if you contact our support team with a request, we respond to you via email. You have a lot of control over how your email address is used and shared on and through TensorParc. You may manage your communication preferences in your user profile.
</p>

<p>
Depending on your email settings, TensorParc may occasionally send notification emails about changes in a project you’re watching, new features, requests for feedback, important policy changes, or to offer customer support. We also send marketing emails, based on your choices and in accordance with applicable laws and regulations. There's an “unsubscribe” link located at the bottom of each of the marketing emails we send you. Please note that you cannot opt out of receiving important communications from us, such as emails from our Support team or system emails, but you can configure your notifications settings in your profile to opt out of other communications.
</p>


<b>Resolving complaints</b>
<p>
If you have concerns about the way TensorParc is handling your user personal information, please let us know immediately. We want to help. You may contact us by writing us at privacy@tensorparc.com. We will respond promptly — within 30 days at the latest.
</p>
<p>
Additionally, if you are a resident of an EU member state, you have the right to file a complaint with your local supervisory authority.
We are subject to the jurisdiction of the U.S. Federal Trade Commission (FTC).
</p>

<b>Changes to our privacy statement</b>
<p>
Although most changes are likely to be minor, we may change our privacy statement from time to time. We will provide notification to users of material changes to this privacy statement through our website at least 30 days prior to the change taking effect by posting a notice on this page or sending email to the primary email address specified in your TensorParc account.  We encourage you to check this site frequently.
</p>                      </Paper>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
            </main>
        </div>
    );
}

export default Impressum;

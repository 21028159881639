import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Draggable } from 'react-beautiful-dnd';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Icon from "@material-ui/core/Icon/Icon";

const mystyles = {
    container: {
        marginBottom: "8px",
        fontFamily: '"PT Mono", monospace',
        borderRadius: "10px",
        padding: "8px",
        backgroundColor: "rgb(33, 150, 243)",
        color: "white",
        borderBottom: "1px solid",
        borderBottomColor: "rgb(217, 217, 217)",
        fontSize: "16px",
    }
};

const styles = theme => (mystyles);

class Task extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Draggable draggableId={this.props.task.id} index={this.props.index}>
                {(provided, snapshot) => (
                    <Container
                        className={classes.container}
                         {...provided.draggableProps}
                         {...provided.dragHandleProps}
                            ref={provided.innerRef}
                    ><Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Box>{this.props.task.content}</Box>
                        {this.props.colId === 'column-1' && (<Box><Icon style={{ fontSize: "15px", cursor: "pointer" }} onClick={() => this.props.onDeleteItem('column-1', this.props.task.id, this.props.index, this.props.task.content)}>close</Icon></Box>)}
                    </Box></Container>
                )}
            </Draggable>
        )
    }

}

export default withStyles(styles, { withTheme: true })(Task);

import React from "react";
import Home from "../containers/Home";
import Impressum from "../containers/Impressum";
import Stats from "../containers/Stats";
import LandingPage from "../containers/LandingPage";
import Blog from "../containers/Blog";
import Aboutus from "../containers/Aboutus";
import Privacy from "../containers/Privacy";
import TOU from "../containers/TOU";
import Jobs from "../containers/Jobs";
import Signin from "../containers/Signin";
import Signup from "../containers/Signup";
import ConfirmRegister from "../containers/ConfirmRegister";
import ConfirmDeletion from "../containers/ConfirmDeletion";
import { IDE } from "../components/IDE";
import { defaultEditorStrings } from "../components/IDE/config";

const publicRoutes = [
    {
        component: LandingPage, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/" // <-- ... and this is the actual path to match on
    },
    {
        component: LandingPage, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/load/:ide?" // <-- ... and this is the actual path to match on
    },
    {
        component: Home, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Home" // <-- ... and this is the actual path to match on
    },
    {
        component: Jobs, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Jobs" // <-- ... and this is the actual path to match on
    },
    {
        component: TOU, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/TOU" // <-- ... and this is the actual path to match on
    },
    {
        component: Aboutus, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Aboutus" // <-- ... and this is the actual path to match on
    },
    {
        component: Privacy, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Privacy" // <-- ... and this is the actual path to match on
    },
    {
        component: Blog, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Blog" // <-- ... and this is the actual path to match on
    },
    {
        component: Impressum, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Impressum" // <-- ... and this is the actual path to match on
    },
    {
        component: Stats, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Stats" // <-- ... and this is the actual path to match on
    },
    {
        component: Signin, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Signin" // <-- ... and this is the actual path to match on
    },
    {
        component: Signup, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/Signup" // <-- ... and this is the actual path to match on
    },
    {
        component: LandingPage, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/LandingPage" // <-- ... and this is the actual path to match on
    },
    {
        component: ConfirmRegister, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/ConfirmRegister/:code" // <-- ... and this is the actual path to match on
    },
    {
        component: ConfirmDeletion, // <-- this is the component that'll be rendered
        exact: true, // <-- this says to ONLY match when the path is exactly '/'
        path: "/ConfirmDeletion" // <-- ... and this is the actual path to match on
    },
    {
    component: () => (
      <IDE
        name="IDE-PYTHON"
        mode="python"
        value={defaultEditorStrings.python}
        defaultValue={defaultEditorStrings.python}
      />
    ),
    exact: true,
    path: "/IDE-PY"
  },
  {
    component: () => (
      <IDE
        name="IDE-JAVA"
        mode="java"
        value={defaultEditorStrings.java}
        defaultValue={defaultEditorStrings.java}
      />
    ),
    exact: true,
    path: "/IDE-JAVA"
  },
  {
    component: () => (
      <IDE
        name="IDE-JS"
        mode="javascript"
        splits={2}
        value={defaultEditorStrings.js}
        defaultValue={defaultEditorStrings.js}
      />
    ),
    exact: true,
    path: "/IDE-JS"
  },
];


export { publicRoutes };

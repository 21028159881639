import React, { PureComponent } from 'react';
import './style.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import UserContext, { UserConsumer } from "../../userContext";
import Footer from "../../components/Footer/Footer.js";
import Moment from 'react-moment';
import 'moment-timezone';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import Edit from '@material-ui/icons/Edit';
import stylescss from "../../assets/jss/material-kit-react/views/landingPage.js";
import {api} from "../../lib/apisauce";
import Loader from "../../components/Loader";
import Header from "../../components/Header/Header";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import Box from "@material-ui/core/Box";
import {Link} from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from "../../components/CustomButtons/Button.js";
import { Redirect } from 'react-router'
const styles = theme => (stylescss);

const dashboardRoutes = [];

class Home extends PureComponent {
    static contextType = UserContext;

    state = {
        loading: false,
        open2: false,
        userIdes: [],
        onlyContexts: [],

    }

    redirectToTarget = () => {
        this.props.history.push(`/ConfirmDeletion`)
      }

    componentDidMount() {
        if (this.context.isLoggedIn) { this.loadUserSavedItems(this.context.username); }
    }

    render() {
        const { classes } = this.props;

        if (!this.context.isLoggedIn) {
            return (
                <Redirect
                    to={{
                        pathname: `/Signin`,
                        state: {
                        }
                    }}
                />
            );
        }

        return (
            <UserConsumer>
                {({ username, password, isLoggedIn, loginFailure }) => (
                    <div className={classes.root}>
                    {this.state.loading && <div className={classes.loader}>
                        <Loader />
                    </div>}
                        <Header
                            color="transparent"
                            backgroundColor="rgb(244, 244, 245)"
                            routes={dashboardRoutes}
                            brand="The world's new learning front page."
                            fixed
                            changeColorOnScroll={{
                                height: 400,
                                color: "white"
                            }}
                        />

                        <div style={{ backgroundColor: "rgb(236, 236, 236)", paddingBottom: "4rem", paddingTop: "5rem" }}>

                            <GridContainer className={classes.pagecontainer} style={{ paddingTop: "5rem" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                <Grid item>
                                </Grid>
                                    <h1 className={classes.title} style={{ paddingBottom: "0rem" }}> Welcome {username}, this is your dashboard. </h1>
                                    <h3 style={{ paddingBottom: "3rem" }}>Recently edited projects: </h3>

                                    {this.state.open2 && (
                                        <Grid container>
                                            {this.state.onlyContexts.map((ide, ind) => {
                                                return (
                                                    <Box style={{ width: "100%", backgroundColor: "#eee" }}>
                                                        <Box className={classes.prelist} style={{ backgroundColor: "rgb(63, 64, 63, 0.87)", color: "#fff", cursor: "initial" }} key={ind}>{ide[0]}</Box>
                                                        <Box style={{ display: "flex" }}>
                                                        {this.state.userIdes.map((items, index) => {
                                                            if (items.context === ide[0]) return (
                                                                <Link to={"/load/"+items.id} style={{ color: "white", textDecoration: "none" }}>
                                                                    <Box className={classes.prelist} key={index} style={{  }}>

                                                                    <Typography gutterBottom>
                                                                        Your Topics: {items.research}
                                                                    </Typography>

                                                                    <div style={{ margin: "0 auto", textAlign: "center", alignSelf: "right", width:"6rem", padding: "1rem"}}>
                                                                        <CircularProgressbar
                                                                            value={items.score}
                                                                            text={`${items.score}%`}
                                                                            styles={buildStyles({
                                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                                strokeLinecap: 'butt',

                                                                                // Colors
                                                                                pathColor: '#a9a9a9',
                                                                                textColor: '#fff',
                                                                                maxHeight: "6rem",
                                                                            })}
                                                                        />
                                                                    </div>

                                                                    <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                                                        <img className={classes.img} alt="complex" src="https://i.imgur.com/VudgSQK.png" width="20vh" height="auto" /> You worked on it <Moment fromNow ago>{items.crdate}</Moment> ago
                                                                    </Typography>

                                                                    </Box>
                                                                </Link>
                                                            )
                                                        })}
                                                        </Box>
                                                    </Box>

                                                )

                                            })}

                                        </Grid>)}
                                    </GridItem>
                                <GridItem xs={3} sm={3} md={3} style={{marginTop: "10rem"}}>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={event => {
                                        delete(this.deleteUser(this.context.username, this.redirectToTarget));
                                    }}
                                >
                                    Delete Account
                                </Button>
                                <text style={{fontSize: "12px", textAlign: "center", color: "grey"}}> If you press this button, your account will be permanently deleted. This means that your account, account settings, projects will be deleted. If others have used your projects, they will still be able to use them. Are you sure you want to do this? Everytime a user is leaving Isa cries at night.</text>
                                </GridItem>
                            </GridContainer>
                            <Footer/>
                        </div>
                    </div>
                )}
            </UserConsumer>
        );
    }

    /**
     *
     * @method loadUserSavedItems
     * @memberof LandingPage
     * WIP
     */
    loadUserSavedItems = async (user) => {
        this.setState({ loading: true });
        const response = await api.post(
            "https://apitp.tensorparc.com/getItems.php",
            { user }
        );
        const { ok, data } = response || {};
        if (ok) {

            console.log(data)

            if(data.result.status === 'no ide found by that user') {
                this.setState({loading: false});
            } else {

                this.setState({userIdes: data.result});


                const onlyContexts = data.result.reduce((obj, {context, id}) => {
                    const dateArr = obj[context] || [];
                    dateArr.push(context);
                    return {...obj, [context]: dateArr};
                }, {});

                const onlyContextsA = Object.values(onlyContexts);

                this.setState({onlyContexts: onlyContextsA});
                console.log(onlyContextsA)

                this.setState({loading: false});
                this.setState({open2: true});
            }

        } else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("saveDashboard() Error", { data });
            }
            this.setState({ loading: false });
        }
    };
    /**
     *
     * @method deleteUser
     * @memberof LandingPage
     * WIP
     */
    deleteUser = async (id) => {

        const response = await api.post(
            "https://apitp.tensorparc.com/deleteUser.php",
            { id }
        );
        const {ok, data} = response || {};
        if (ok) {
            this.setState(this.redirectToTarget);

            }
        else {
            // handle api call error
            if (process.env.REACT_APP_DEBUG === true) {
                console.error("It didn't work. The user is still alive.");
            }
        }
    };
}


export default withStyles(styles, { withTheme: true })(Home);

import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import $ from "jquery"
const dashboardRoutes = [];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    datefont: {
      color: '#bbb',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        padding: 0,
        margin: 0,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

function Impressum() {
    const classes = useStyles();
    $(document).ready(function(){
    $(".blogchars9").html($("#post9").text().length);
    $(".blogchars8").html($("#post8").text().length);
    $(".blogchars7").html($("#post7").text().length);
    $(".blogchars6").html($("#post6").text().length);
    $(".blogchars5").html($("#post5").text().length);
    $(".blogchars4").html($("#post4").text().length);
    $(".blogchars3").html($("#post3").text().length);
    $(".blogchars2").html($("#post2").text().length);
    $(".blogchars1").html($("#post1").text().length);
    $(".readingtime9").html(($("#post9").text().length)*3/100);
    $(".readingtime8").html(($("#post8").text().length)*3/100);
    $(".readingtime7").html(($("#post7").text().length)*3/100);
    $(".readingtime6").html(($("#post6").text().length)*3/100);
    $(".readingtime5").html(($("#post5").text().length)*3/100);
    $(".readingtime4").html(($("#post4").text().length)*3/100);
    $(".readingtime3").html(($("#post3").text().length)*3/100);
    $(".readingtime2").html(($("#post2").text().length)*3/100);
    $(".readingtime1").html(($("#post1").text().length)*3/100);
    });
    //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <main className={classes.content}>
            <Header
                color="transparent"
                backgroundColor="rgb(244, 244, 245)"
                routes={dashboardRoutes}
                brand="Isa: We value your time. Our blog posts will have < 100 chars."
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
            />
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>January 15, 2020</p>
                            <h3 className={classes.title}>Welcome new users!</h3><h4 id="post8"> We welcome all new test users in 2020.</h4>
                            <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars8"> </span>,  reading time: <span className="readingtime8"> </span> sec</div>
                        </Paper>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>December 22, 2019</p>
                            <h3 className={classes.title}>Great Holidays!</h3><h4 id="post8"> We wish you all the best for the holidays..</h4>
                            <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars8"> </span>,  reading time: <span className="readingtime8"> </span> sec</div>
                        </Paper>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>December 7, 2019</p>
                            <h3 className={classes.title}>Better employees</h3><h4 id="post7"> Don't just learn for good grades. Employers will notice if you love a topic.</h4>
                            <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars7"> </span>,  reading time: <span className="readingtime7"> </span> sec</div>
                        </Paper>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>December 5, 2019</p>
                            <h3 className={classes.title}>Self-Directed learning</h3><h4 id="post6"> Self-directed learning will be standard. Think about it. Talk to us.</h4>
                            <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars6"> </span>,  reading time: <span className="readingtime6"> </span> sec</div>
                        </Paper>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>December 4, 2019</p>
                            <h3 className={classes.title}>We added sharing</h3><h4 id="post5"> Today we added sharing. You can follow experts. </h4>
                            <span> Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars5"> </span>,  reading time: <span className="readingtime5"> </span> sec</div>
                        </Paper>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>December 2, 2019</p>
                            <h3 className={classes.title}>We are not perfect</h3><h4 id="post4"> Our product improves daily. Please help us with feedback. </h4>
                            <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars4"> </span>,  reading time: <span className="readingtime4"> </span> sec</div>
                        </Paper>
                        <Paper className={classes.paper}>
                        <p className={classes.datefont}>November 19, 2019</p>
                            <h3 className={classes.title}>Don't lie to yourself</h3><h4 id="post3"> Lying to yourself while learning is your worst enemy. </h4>
                            <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                            </a></span><div className={classes.datefont}>Chars: <span className="blogchars3"> </span>,  reading time: <span className="readingtime3"> </span> sec</div>
                        </Paper>
                            <Paper className={classes.paper}>
                            <p className={classes.datefont}>November 14, 2019</p>
                                <h3 className={classes.title}>Why is the name of the bunny Isa?</h3><h4 id="post2"> Isa returns a function in Bel that tests. </h4>
                                <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                                </a></span><div className={classes.datefont}>Chars: <span className="blogchars2"> </span>,  reading time: <span className="readingtime2"> </span> sec</div>
                            </Paper>
                            <Paper className={classes.paper}>
                                <p className={classes.datefont}>November 13, 2019</p>
                                <h3 className={classes.title}>Happy Birthday Isa <span role="img" aria-label="happy">&#129365;</span> </h3><h4 id="post1">Isa is the world's new online learning by creation tool. </h4>
                                <span>Reply with a <a className={classes.block} target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/tweet?text=Create%20something%20interesting%20with%20Isa%20from%20https://www.TensorParc.com%20" data-size="large">Tweet
                                </a></span><div className={classes.datefont}>Chars: <span className="blogchars1"> </span>,  reading time: <span className="readingtime1"> </span> sec</div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </main>
        </div>
    );
}

export default Impressum;

import React from 'react';
import './style.css';
//import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HeaderLinks from "../../components/Header/HeaderLinks.js";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
const dashboardRoutes = [];

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: '3em',
        marginBottom: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

function Impressum() {
    const classes = useStyles();

    //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    return (
        <div className={classes.root}>
            <main className={classes.content}>
            <Header
                color="transparent"
                backgroundColor="rgb(244, 244, 245)"
                routes={dashboardRoutes}
                brand="Welcome to TensorParc. My name is Isa."
                rightLinks={<HeaderLinks/>}
                fixed
                changeColorOnScroll={{
                    height: 400,
                    color: "white"
                }}
            />
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* Recent Orders */}
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <h3>Welcome to TensorParc</h3>
                                <p>We are a team of friends that have set out to democratize learning and research. <br/> The majority accepts supply and demand to rule our economic matters. But when it comes to education, in particular what learning, most people are happy with a fixed curriculum.  This is
                                fine for the first 8 years of school.<br/> <br/> Beyond, education has to be free enough to allow people to study what interests them. Today they only learn to pass tests.<br/>
                                Imagine a world, where people learn for the sake of learning only. This, we believe, happens if people can solely learn what they want.
                                Some nerd talk: We see us as a <a href="/" title="if you want to learn more about recursive functions, please feel free to use our tool Isa">recursive function</a> that makes sure everyone gets an A in anything they want. We founded TensorParc. The first product is called Isa.
                                Isa is named after a function that calls other functions to check stuff.<br/><br/>
                                We are building a product with the least amount of side effects possible. From your perspective, side-effects are features that do not return a value to you, the user.
                                Technically we focus on functional programming and clean design. That is why our app might look simple, but the key functions we provide are powerful.
                                <br/><br/>
                                If you have ideas that we should try, please let us know.<br/><br/>
                                We are Tony Meyer, Farid Bidardel, Tobias Lang, Karan Dehghani.</p>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                    <Footer/>
            </main>
        </div>
    );
}

export default Impressum;
